
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
// import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
// import Information1 from "@/components/dropdown/Information1.vue";
// import ElCurrencyInput from "@/components/CurrencyInput.vue";
// import { ElLoading } from "element-plus";
// import moment from "moment";
// import { Action } from "vuex-module-decorators";
// import { object } from "yup/lib/locale";
// import ElInfo from "@/components/ElInfo.vue";

export default defineComponent({
  name: "organisation-form",
  components: {
    Field,
    Form,
  },
  async mounted() {
    this.init();
  },
  data() {
    const route = useRoute();
    const i18n = useI18n();
    // const schema = Yup.object({});
    const schema = Yup.object({
      organisation: Yup.object({
        name: Yup.string()
          .required(i18n.t('organisation.validations.name.required')),
        org_type: Yup.string()
          .required(i18n.t("organisation.validations.org_type.required")),
        address: Yup.string()
          .required(i18n.t("organisation.validations.address.required")),
      }),
    });

    return {
      id: route.params.id ?? undefined,
      i18n: i18n,
      router: useRouter(),
      route: route,
      store:useStore(),
      showParentId:false,
      schema: schema,
      organisation_type: [
        { id: 'branch', name: "Branch" },
        { id: 'fa_office', name: "Office" },
      ],
      branchs: [],
    };
  },
  setup() {
    const {t} = useI18n();
    return {
      t,
    };
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("pages.organisation.add", ["pages.organisation.title"]);
      const form = this.$refs.form as any;

      // Load Branch list
      this.store.dispatch(Actions.GET_BRANCHES)
      .then( () => this.branchs = this.store.getters.getBranchData);

      // Load organisations
      if(this.id){
        this.store.dispatch(Actions.GET_ORGANISATION,this.id)
        .then( () => {

          form.setValues({'organisation':this.store.getters.getOrganisationData})
          this.orgChange(this.store.getters.getOrganisationData.org_type);
        });
      }else{
        form.resetForm();
      }
    },
    onSubmit(values) {

      const action = this.id ? Actions.UPDATE_ORGANISATION : Actions.STORE_ORGANISATION;

      this.store.dispatch(action,values.organisation)
      .then( () => {
        Swal.fire({
            title: this.i18n.t('flash.success.title'),
            text: this.i18n.t('flash.success.message'),
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then( () => this.router.push({name:'pages.organisation.list'}))
      })

    },
    orgChange(value){
      this.showParentId = (value == 'fa_office') ?? false;
    }
  },
});
